import { Card, Checkbox } from 'antd';
import React, { useEffect } from 'react';
import { RateDto } from '../../../lib/shared/types';
import { FormNavigation } from '../../components';
import { ViewProps } from '../view-props';
import { OfferSelectionTable } from './components';
import './offer-selection-view.scss';

type Props = ViewProps & {
    rates: readonly RateDto[];
};

export const OfferSelectionView: React.FC<Props> = ({
    rates,
    formState,
    updateFormState,
    recede,
    proceed,
}: Props) => {
    const onChange = (rate: RateDto): void => {
        updateFormState({ ...formState, selectedRate: rate });
    };

    const validate = (): boolean => {
        if (!formState.selectedRate) {
            return false;
        }
        return true;
    };

    const onFinish = (): void => {
        if (validate()) {
            proceed();
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (
            formState.selectedRate &&
            rates.some((rate) => rate.rateID == formState.selectedRate?.rateID)
        ) {
            return;
        }

        updateFormState({ ...formState, selectedRate: rates[rates.length - 1] });
    }, [rates]);

    return (
        <Card className='view-card'>
            <h2 style={{ fontFamily: 'Merriweather-Bold !important', marginLeft: '2.5rem' }}>
                {'Wählen Sie Ihren Wunschtarif!'}
            </h2>
            <br />
            <OfferSelectionTable
                dataRows={rates}
                selected={formState.selectedRate ?? rates[rates.length - 1]}
                select={onChange}
            />
            <br />
            <span>
                {
                    'Verkürzte Darstellung der in den allgemeinen Versicherungsbedingungen niedergeschriebenen Versicherungs- und Leistungsbausteine. Es gelten die Bedingungen des Versicherungsvertrages des Risikoträgers'
                }
            </span>
            <div className='newsletter-box'>
                <Checkbox
                    checked={formState.driverData.happySurance}
                    onChange={(event) => {
                        updateFormState({
                            ...formState,
                            driverData: {
                                ...formState.driverData,
                                happySurance: event.target.checked,
                            },
                        });
                    }}
                >
                    {'Gerne möchte ich weitere Informationen zur Minikasko von '}
                    <b>{'Happy Surance'}</b>
                    {' erhalten.'}
                    <br />
                    {
                        'Happy Surance - die clevere Versicherung für kleine Missgeschicke im Straßenverkehr.'
                    }
                </Checkbox>
            </div>
            <FormNavigation recede={recede} proceed={onFinish} />
        </Card>
    );
};
