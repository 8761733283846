import { LoadingOutlined } from '@ant-design/icons';
import { Card } from 'antd';
import React, { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useAxios } from '../lib/axios';
import { Endpoints } from '../lib/shared/endpoints';
import {
    CustomerDataResponseDto,
    GeneratePdfValues,
    InsuranceRateResponseDto,
    ModelListResultDto,
    ModelSearchResultDto,
    RateDto,
    VehicleType,
} from '../lib/shared/types';
import { ProgressBreadcrumbs } from './components';
import './main-page.scss';
import { FormState } from './state';
import {
    CustomerLoginView,
    DealerLoginView,
    DriverDataView,
    LandingView,
    NoOfferView,
    OfferSelectionView,
    PersonalDataView,
    PreviousInsuranceView,
    ResultView,
    SummaryView,
    VehicleDataView,
} from './views';

const emptyPrefillings = {
    manufacturerSelection: [],
    paymentMethodSelection: [],
    mileageSelection: [],
    predamageSelection: [],
};

type LoginValues = {
    dealerNumber?: string | undefined;
    sellerNumber?: string | undefined;
    email?: string | undefined;
};

type PrefillingOptions = {
    manufacturerSelection: readonly string[];
    paymentMethodSelection: readonly string[];
    mileageSelection: readonly string[];
    predamageSelection: readonly string[];
};

export const MainPage: React.FC = () => {
    const axios = useAxios();

    const [customerLogin, setCustomerLogin] = useState<boolean>(false);
    const [formState, setFormState] = useState<FormState | null>(null);
    const [downloadParameters, setDownloadParameters] = useState<GeneratePdfValues | undefined>(
        undefined
    );
    const [progress, setProgress] = useState<number>(0);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [prefillings, setPrefillings] = useState<PrefillingOptions>(emptyPrefillings);
    const [modelOptions, setModelOptions] = useState<readonly string[]>([]);
    const [poweredByOptions, setPoweredByOptions] = useState<readonly string[]>([]);
    const [vehicleOptions, setVehicleOptions] = useState<readonly VehicleType[]>([]);
    const [rates, setRates] = useState<readonly RateDto[]>([]);

    const onLoginSubmitted = async (values: LoginValues): Promise<boolean> => {
        try {
            const response = await axios.post<CustomerDataResponseDto>(Endpoints.LOGIN, {
                haendlerNr: values.dealerNumber,
                verkaeuferNr: values.sellerNumber,
                email: values.email,
            });

            if (response.data && response.data.status === 'Accepted') {
                setFormState({
                    user: {
                        ...response.data,
                    },
                    vehicleData: {},
                    driverData: {
                        cityOptions: [],
                        youngDriver: false,
                        happySurance: false,
                    },
                    previousInsurance: {
                        terminated: false,
                    },
                    personalData: {
                        classification: 'PRIVAT',
                        email: response.data.email,
                    },
                });
                setProgress(2);
                return true;
            } else {
                setFormState(null);
                setProgress(0);
                return false;
            }
        } catch (e) {
            setFormState(null);
            setProgress(0);
            return false;
        }
    };

    const switchToCustomerLogin = (): void => {
        setCustomerLogin(true);
    };

    const switchToDealerLogin = (): void => {
        setCustomerLogin(false);
    };

    const onMakeChanged = async (make?: string | undefined): Promise<void> => {
        if (make && make.length > 0) {
            try {
                const response = await axios.get<ModelListResultDto>(
                    `${Endpoints.MODELLIST}?manufacturer=${make}`
                );
                if (response.data) {
                    setModelOptions(response.data.modelCat);
                }
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error(e);
                setModelOptions([]);
            }
        } else {
            setModelOptions([]);
        }
    };

    const onModelChanged = async (
        make?: string | undefined,
        model?: string | undefined,
        poweredBy?: string | undefined
    ): Promise<void> => {
        if (make && make.length > 0 && model && model.length > 0) {
            try {
                const response = await axios.get<ModelSearchResultDto>(
                    `${Endpoints.SEARCHMODEL}?manufacturer=${make}&modelCat=${model}${
                        poweredBy ? `&poweredBy=${poweredBy}` : ''
                    }`
                );
                if (response.data) {
                    if (poweredBy && poweredBy.length > 0) {
                        setVehicleOptions(response.data.vehicleType);
                    } else {
                        setPoweredByOptions(
                            response.data.vehicleType
                                .map((vehicle) => vehicle.poweredBy)
                                .filter((value, index, array) => array.indexOf(value) === index)
                        );
                    }
                }
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error(e);
                setVehicleOptions([]);
                setPoweredByOptions([]);
            }
        } else {
            setVehicleOptions([]);
            setPoweredByOptions([]);
        }
    };

    const recede = (): void => {
        if (progress === 2) {
            returnToLogin();
        } else {
            setProgress(progress - 1);
        }
    };

    const proceed = (): void => {
        if (progress === 1) {
            if (formState?.vehicleData?.valid !== 'valid') {
                setProgress(11);
            } else {
                setProgress(2);
            }
        } else if (progress === 3) {
            if (
                formState?.previousInsurance?.predamage ===
                prefillings.predamageSelection[prefillings.predamageSelection.length - 1]
            ) {
                setProgress(11);
            } else {
                setProgress(4);
            }
        } else {
            setProgress(progress + 1);
        }
    };

    const returnToLogin = (): void => {
        setCustomerLogin(false);
        setFormState(null);
        setProgress(0);
    };

    // Return to login after 5min
    useIdleTimer({
        onIdle: returnToLogin,
        timeout: 5 * 60 * 1000,
        throttle: 500,
    });

    useEffect(() => {
        const fetchPrefill = async (): Promise<void> => {
            setIsLoading(true);
            try {
                const response = await axios.get<PrefillingOptions>(Endpoints.PREFILLINGS);
                if (response.data) {
                    setPrefillings(response.data);
                }
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error(e);
            } finally {
                setIsLoading(false);
            }
        };

        fetchPrefill();
    }, []);

    useEffect(() => {
        const fetchRates = async (): Promise<void> => {
            if (!formState) {
                return;
            }

            setIsLoading(true);
            const dto = {
                token: formState.user.token,
                procedureID: formState.user.procedureID,
                cashPurchase: formState.vehicleData.paymentOption === 'BARKAUF / KREDIT',
                leasing: formState.vehicleData.paymentOption === 'LEASING',
                mileage: formState.vehicleData.expectedMileage,
                paymentMethode: formState.driverData.paymentMethod,
                postalCode: formState.driverData.postalCode,
                dob: formState.driverData.dateOfBirth,
                youngDriver: formState.driverData.youngDriver,
                youngDriverDob: formState.driverData.dateOfBirthYoungest,
                terminationPreviousInsurer: formState.previousInsurance.terminated,
                predamage: formState.previousInsurance.predamage,
                initialRegistration: formState.vehicleData.firstRegistration?.format('YYYY-MM-DD'),
                hsn: '1590',
                tsn: formState.vehicleData.tsn,
            };
            try {
                const response = await axios.post<InsuranceRateResponseDto>(
                    Endpoints.INSURANCERATE,
                    dto
                );
                if (response.data && response.data.status === 'valid') {
                    setRates(response.data.rates);
                } else {
                    setRates([]);
                    setProgress(11);
                }
            } catch {
                setRates([]);
                setProgress(11);
            } finally {
                setIsLoading(false);
            }
        };

        if (progress === 5) {
            fetchRates();
        } else {
            setRates([]);
        }
    }, [progress]);

    const showLandingView = !formState && progress === 0;
    const showDealerLoginView = !formState && !customerLogin && progress === 1;
    const showCustomerLoginView = !formState && customerLogin && progress === 1;
    const showVehicleDataView = formState && progress === 2;
    const showDriverDataView = formState && progress === 3;
    const showPreviousInsuranceView = formState && progress === 4;
    const showOfferSelectionView = formState && progress === 5;
    const showPersonalDataView = formState && progress === 6;
    const showSummaryView = formState && progress === 7;
    const showResultView = formState && progress === 8;
    const showNoOfferView = formState && progress === 11;

    return (
        <div className='main-page-container'>
            {progress > 1 && progress < 10 && (
                <div className='main-page-content-container' style={{ marginTop: 0 }}>
                    <Card className='view-card '>
                        <ProgressBreadcrumbs stage={progress - 1} gotoProgress={setProgress} />
                    </Card>
                </div>
            )}
            <div className='main-page-content-container'>
                {isLoading && <LoadingOutlined className='main-page-loading' />}
                {showLandingView && (
                    <LandingView setCustomerLogin={setCustomerLogin} proceed={proceed} />
                )}
                {showDealerLoginView && (
                    <DealerLoginView
                        onFormSubmit={onLoginSubmitted}
                        gotoCustomerLogin={switchToCustomerLogin}
                    />
                )}
                {showCustomerLoginView && (
                    <CustomerLoginView
                        onFormSubmit={onLoginSubmitted}
                        gotoDealerLogin={switchToDealerLogin}
                    />
                )}
                {showVehicleDataView && (
                    <VehicleDataView
                        prefillOptions={prefillings}
                        modelOptions={modelOptions}
                        poweredByOptions={poweredByOptions}
                        vehicleOptions={vehicleOptions}
                        formState={formState}
                        onMakeSelected={onMakeChanged}
                        onModelSelected={onModelChanged}
                        updateFormState={setFormState}
                        recede={recede}
                        proceed={proceed}
                    />
                )}
                {showDriverDataView && (
                    <DriverDataView
                        options={prefillings}
                        formState={formState}
                        updateFormState={setFormState}
                        recede={recede}
                        proceed={proceed}
                    />
                )}
                {showPreviousInsuranceView && (
                    <PreviousInsuranceView
                        options={prefillings}
                        formState={formState}
                        updateFormState={setFormState}
                        recede={recede}
                        proceed={proceed}
                    />
                )}
                {showOfferSelectionView && (
                    <OfferSelectionView
                        rates={rates}
                        formState={formState}
                        updateFormState={setFormState}
                        recede={recede}
                        proceed={proceed}
                    />
                )}
                {showPersonalDataView && (
                    <PersonalDataView
                        formState={formState}
                        updateFormState={setFormState}
                        recede={recede}
                        proceed={proceed}
                    />
                )}
                {showSummaryView && (
                    <SummaryView
                        formState={formState}
                        updateFormState={setFormState}
                        recede={recede}
                        proceed={proceed}
                        updateDownloadParameters={setDownloadParameters}
                    />
                )}
                {showResultView && (
                    <ResultView
                        processNumber={formState.user.procedureID}
                        downloadParameters={downloadParameters}
                        returnToLandingPage={returnToLogin}
                    />
                )}
                {showNoOfferView && <NoOfferView returnToLandingPage={returnToLogin} />}
            </div>
        </div>
    );
};
