import { Radio } from 'antd';
import React from 'react';
import { VehicleType } from '../../../../../lib/shared/types';
import './vehicle-card.scss';

type Props = {
    vehicleType: VehicleType;
    selected: boolean;
    canSelect?: boolean;
    select?: (vehicleType: VehicleType) => void;
};

export const VehicleCard: React.FC<Props> = ({
    vehicleType,
    selected,
    canSelect,
    select,
}: Props) => {
    return (
        <div
            className={`vehicle-card${selected ? ' active' : ''}${canSelect ? ' clickable' : ''}`}
            role='presentation'
            onClick={() => canSelect && select && select(vehicleType)}
        >
            <Radio checked={selected} style={{ gridArea: 'radio' }} />
            <span
                style={{ gridArea: 'header' }}
            >{`${vehicleType.manufacturer} ${vehicleType.typ}`}</span>
            <span style={{ gridArea: 'powerheader' }}>
                <b>{'Leistung'}</b>
            </span>
            <span style={{ gridArea: 'yearheader' }}>
                <b>{'Baujahr'}</b>
            </span>
            <span style={{ gridArea: 'idheader' }}>
                <b>{'HSN/TSN'}</b>
            </span>
            <span style={{ gridArea: 'power' }}>{vehicleType.perfomance}</span>
            <span style={{ gridArea: 'year' }}>{vehicleType.constructionYear}</span>
            <span style={{ gridArea: 'id' }}>{`${vehicleType.hsn}/${vehicleType.tsn}`}</span>
        </div>
    );
};
