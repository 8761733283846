import { Button, Card, Tooltip } from 'antd';
import React, { useEffect } from 'react';
import { Endpoints } from '../../../lib/shared/endpoints';
import { CustomerPostDto, ConfirmationDto, GeneratePdfValues } from '../../../lib/shared/types';
import { FormSection } from '../../components';
import { ViewProps } from '../view-props';
import { SummaryTable } from './components';
import './summary-view.scss';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { useAxios } from '../../../lib/axios';

type Props = ViewProps & {
    updateDownloadParameters: (data?: GeneratePdfValues) => void;
};

export const SummaryView: React.FC<Props> = ({
    formState,
    recede,
    proceed,
    updateDownloadParameters,
}: Props) => {
    const insuranceData = [
        {
            key: formState.selectedRate?.rateName,
            value: 'Kfz-Vollkasko und -Haftpflicht',
        },
        {
            key: 'Tarif',
            value: `${formState.selectedRate?.rate} ${formState.driverData.paymentMethod} inkl. Versicherungssteuer`,
        },
        {
            key: 'Selbstbeteiligung(SB)',
            value: formState.selectedRate?.deductible,
        },
        {
            key: 'Zahlungsperiode',
            value: formState.driverData.paymentMethod,
        },
    ];

    const vehicleData = [
        {
            key: 'Fahrzeugart',
            value: 'Pkw',
        },
        {
            key: 'Marke',
            value: formState.vehicleData.make,
        },
        {
            key: 'Modell',
            value: formState.vehicleData.model,
        },
        {
            key: 'Herstellernummer (HSN)',
            value: '1590',
        },
        {
            key: 'Typschlüsselnummer (TSN)',
            value: formState.vehicleData.tsn,
        },
        {
            key: 'Leistung',
            value: formState.vehicleData.perfomance,
        },
        {
            key: 'Erstzulassung',
            value: formState.vehicleData.firstRegistration?.format('DD.MM.YYYY'),
        },
        {
            key: 'Baujahr',
            value: formState.vehicleData.buildYear,
        },
        {
            key: 'Fahrleistung',
            value: formState.vehicleData.expectedMileage,
        },
        {
            key: 'Finanzierung',
            value: formState.vehicleData.paymentOption,
        },
    ];

    const personalData = [
        {
            key: 'Firma',
            value: formState.personalData.companyName,
        },
        {
            key: 'Vorname, Nachname',
            value: `${formState.personalData.firstName}, ${formState.personalData.lastName}`,
        },
        {
            key: 'Geburtsdatum',
            value: formState.driverData.dateOfBirth?.format('DD.MM.YYYY'),
        },
        {
            key: 'Straße / Hausnummer / Adresszusatz',
            value: `${formState.personalData.street} ${formState.personalData.houseNumber} ${
                formState.personalData.addressExtension ?? ''
            }`,
        },
        {
            key: 'PLZ, Wohnort',
            value: `${formState.driverData.postalCode} ${formState.driverData.city}`,
        },
        {
            key: 'E-Mail',
            value: formState.personalData.email,
        },
        {
            key: 'Telefonnummer',
            value: formState.personalData.phoneNumber,
        },
        {
            key: 'Weitere Fahrer unter 25 Jahren',
            value: formState.driverData.youngDriver
                ? `Ja / Geburtsdatum ${formState.driverData.dateOfBirthYoungest?.format(
                      'DD.MM.YYYY'
                  )}`
                : 'Nein',
        },
    ];

    const previousInsuranceData = [
        {
            key: 'Kündigung der Vorversicherung durch Versicherer',
            value: formState.previousInsurance.terminated ? 'Ja' : 'Nein',
        },
        {
            key: 'Schäden der letzten 3 Jahre',
            value: formState.previousInsurance.predamage,
        },
    ];

    const paymentData = [
        {
            key: 'Zahlungsart',
            value: 'SEPA-Lastschrift',
        },
        {
            key: 'IBAN',
            value: formState.personalData.iban,
        },
        {
            key: 'Bank',
            value: formState.personalData.bank,
        },
    ];

    const axios = useAxios();

    const sendApplication = async (digital: boolean): Promise<boolean> => {
        const dto: CustomerPostDto = {
            bank: {
                iban: formState.personalData.iban,
                bankName: formState.personalData.bank,
            },
            customer: {
                ...formState.personalData,
                ...formState.driverData,
            },
            rateID: formState.selectedRate?.rateID,
            happySurance: formState.driverData.happySurance,
            processType: digital ? 'DIGITAL' : 'ANALOG',
            ...formState.user,
        };
        try {
            const response = await axios.post<ConfirmationDto>(Endpoints.CUSTOMER, dto);
            if (response.data && response.data.status === 'Accepted') {
                if (response.data.processType === 'ANALOG') {
                    const parameters = {
                        dealer_email: response.data.dealer.email,
                        ...response.data.pdfData,
                    };
                    updateDownloadParameters(parameters);
                } else {
                    updateDownloadParameters(undefined);
                }
                return true;
            } else {
                updateDownloadParameters(undefined);
                return false;
            }
        } catch {
            return false;
        }
    };

    const onFinish = async (digital: boolean): Promise<void> => {
        const success = await sendApplication(digital);
        if (success) {
            proceed();
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Card className='view-card'>
            <FormSection>
                <h3>
                    <b>{'Versicherungsschutz'}</b>
                </h3>
                <u>{'Vertragsdetails'}</u>
                <SummaryTable data={insuranceData} />
            </FormSection>
            <FormSection>
                <h3>
                    <b>{'Fahrzeug'}</b>
                </h3>
                <SummaryTable data={vehicleData} />
            </FormSection>
            <FormSection>
                <h3>
                    <b>{'Versicherungsnehmer'}</b>
                </h3>
                <SummaryTable data={personalData} />
            </FormSection>
            <FormSection>
                <h3>
                    <b>{'Vorversicherung'}</b>
                </h3>
                <SummaryTable data={previousInsuranceData} />
            </FormSection>
            <FormSection>
                <h3>
                    <b>{'Zahlung'}</b>
                </h3>
                <SummaryTable data={paymentData} />
            </FormSection>
            <div className='newsletter-box'>
                <span>
                    {'Bitte nehmen Sie unsere '}
                    <a
                        href='https://www.hector.global/datenschutz/'
                        target='_blank'
                        rel='noreferrer'
                    >
                        {'Datenschutzhinweise'}
                    </a>
                    {' zur Kenntnis.'}
                </span>
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <Button className='navigation-button-secondary' type='text' onClick={recede}>
                    <ArrowLeftOutlined className='arrow-left' />
                    {'Zurück'}
                </Button>
                <div style={{ flexGrow: 1 }} />
                <Tooltip
                    title={
                        'Sie erhalten den Kfz-Versicherungsantrag als PDF-Dokument zur Unterschrift als Download. Danach schicken Sie uns das gescannte Dokument als E-Mail zurück.'
                    }
                >
                    <Button
                        className='navigation-button'
                        type='primary'
                        onClick={() => onFinish(false)}
                        style={{ maxWidth: '20rem' }}
                    >
                        {'Antrag als PDF ausdrucken & unterschreiben'}
                        <ArrowRightOutlined className='arrow-right' />
                    </Button>
                </Tooltip>
                <Tooltip
                    title={
                        'Sie erhalten eine E-Mail mit einem Link zur digitalen Unterschrift. Mit öffnen dieses Links gelangen Sie zu Ihrem ausgefüllten Antrag, den Sie papierlos digital unterzeichnen und direkt an uns zurücksenden können.'
                    }
                >
                    <Button
                        className='navigation-button'
                        type='primary'
                        onClick={() => onFinish(true)}
                        style={{ maxWidth: '20rem' }}
                    >
                        {'Antrag digital & papierlos unterzeichnen'}
                        <ArrowRightOutlined className='arrow-right' />
                    </Button>
                </Tooltip>
            </div>
        </Card>
    );
};
