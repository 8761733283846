import React from 'react';
import { RoutedContent } from './routing';

export const App: React.FC = () => {
    return (
        <div className='app-container'>
            <RoutedContent />
        </div>
    );
};
