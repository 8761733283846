import { ArrowRightOutlined } from '@ant-design/icons';
import { Button, Card } from 'antd';
import React, { useEffect } from 'react';
import { FormSection } from '../../components';

type Props = {
    returnToLandingPage: () => void;
};

export const NoOfferView: React.FC<Props> = ({ returnToLandingPage }: Props) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Card className='view-card width-limited-card'>
            <div>
                <FormSection>
                    <div>
                        <b>
                            {
                                'Es tut uns leid, dass wir Ihnen aktuell kein Angebot über unseren Tarifrechner anbieten können.'
                            }
                        </b>
                    </div>
                    <br />
                    <br />
                    {
                        'Gerne unterbreiten wir Ihnen ein individuelles Angebot zur Kfz-Versicherung für Ihr Fahrzeug. Bitte kontaktieren Sie uns telefonisch unter +49 (0)6173 33 66 99 2 oder per E-Mail an '
                    }
                    <a href='mailto:kundenbetreuung@jlr-insurance.de'>
                        {'kundenbetreuung@jlr-insurance.de'}
                    </a>
                    {' .'}
                </FormSection>
                <br />
                <div className='flex-container'>
                    <div style={{ flexGrow: 1 }} />
                    <Button
                        className='navigation-button'
                        type='primary'
                        onClick={returnToLandingPage}
                    >
                        {'Zurück zur Startseite'}
                        <ArrowRightOutlined className='arrow-right' />
                    </Button>
                </div>
            </div>
        </Card>
    );
};
