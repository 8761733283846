import { Card, DatePicker, Input, Radio, Select } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useAxios } from '../../../lib/axios';
import { Endpoints } from '../../../lib/shared/endpoints';
import { ModelSearchResultDto, VehicleType } from '../../../lib/shared/types';
import { useDebounce } from '../../../lib/shared/useDebounce';
import { FormField, FormNavigation, FormSection, RequiredNotice } from '../../components';
import { FormState, VehicleDataState } from '../../state';
import { ErrorState } from '../error-state';
import { FormFieldRefs } from '../form-field-refs';
import { ViewProps } from '../view-props';
import { ExpandableSection, VehicleCard } from './components';
import './vehicle-data-view.scss';
import { dateFormat } from '../../../lib/shared/date-format';

type PrefillingOptions = {
    manufacturerSelection: readonly string[];
    paymentMethodSelection: readonly string[];
    mileageSelection: readonly string[];
};

type Props = ViewProps & {
    prefillOptions: PrefillingOptions;
    modelOptions: readonly string[];
    poweredByOptions: readonly string[];
    vehicleOptions: readonly VehicleType[];
    onMakeSelected: (make?: string | undefined) => void;
    onModelSelected: (
        make?: string | undefined,
        model?: string | undefined,
        poweredBy?: string | undefined
    ) => void;
};

const isSelectedVehicle = (vehicleType: VehicleType, state: VehicleDataState): boolean => {
    return (
        vehicleType.manufacturer === state.make &&
        vehicleType.modelcat === state.model &&
        vehicleType.constructionYear === state.buildYear &&
        vehicleType.poweredBy === state.poweredBy &&
        vehicleType.perfomance === state.perfomance &&
        vehicleType.tsn === state.tsn
    );
};

export const VehicleDataView: React.FC<Props> = ({
    prefillOptions,
    modelOptions,
    poweredByOptions,
    vehicleOptions,
    formState,
    onMakeSelected,
    onModelSelected,
    updateFormState,
    recede,
    proceed,
}: Props) => {
    const axios = useAxios();

    const [errors, setErrors] = useState<ErrorState<VehicleDataState>>({});

    const fieldRefs: FormFieldRefs<VehicleDataState> = {
        make: useRef<HTMLDivElement>(null),
        model: useRef<HTMLDivElement>(null),
        poweredBy: useRef<HTMLDivElement>(null),
        tsn: useRef<HTMLDivElement>(null),
        paymentOption: useRef<HTMLDivElement>(null),
        expectedMileage: useRef<HTMLDivElement>(null),
    };

    const select = (vehicleType: VehicleType | undefined): void => {
        const newData = { ...formState.vehicleData };
        newData.make = vehicleType?.manufacturer;
        newData.model = vehicleType?.modelcat;
        newData.buildYear = vehicleType?.constructionYear;
        newData.poweredBy = vehicleType?.poweredBy;
        newData.perfomance = vehicleType?.perfomance;
        newData.tsn = vehicleType?.tsn;
        newData.valid = vehicleType?.status;
        updateFormState({ ...formState, vehicleData: newData });
    };

    const updateVehicleInfo = async (state: FormState): Promise<void> => {
        if (state.vehicleData.tsn?.length === 3) {
            try {
                const response = await axios.get<ModelSearchResultDto>(
                    `${Endpoints.SEARCHMODEL}?hsn=1590&tsn=${state.vehicleData.tsn}`
                );
                if (response.data) {
                    if (response.data.count === 1) {
                        select(response.data.vehicleType[0]);
                        setErrors({ ...errors, tsn: undefined });
                    } else {
                        const newData = { ...formState.vehicleData };
                        newData.make = undefined;
                        newData.model = undefined;
                        newData.buildYear = undefined;
                        newData.poweredBy = undefined;
                        newData.perfomance = undefined;
                        newData.tsn = state.vehicleData.tsn;
                        newData.valid = undefined;
                        updateFormState({ ...state, vehicleData: newData });
                        setErrors({
                            ...errors,
                            tsn: 'Die angegebene TSN passt zu keinem Modell in der Datenbank.',
                        });
                    }
                }
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error(e);
                const newData = {
                    paymentOption: state.vehicleData.paymentOption,
                    expectedMileage: state.vehicleData.expectedMileage,
                };

                updateFormState({ ...state, vehicleData: newData });
            }
        } else {
            const newData = {
                inputSelection: state.vehicleData.inputSelection,
                paymentOption: state.vehicleData.paymentOption,
                expectedMileage: state.vehicleData.expectedMileage,
                tsn: state.vehicleData.tsn,
            };

            updateFormState({ ...state, vehicleData: newData });
        }
    };

    const [debounce] = useDebounce(updateVehicleInfo, 500);

    const onChange = async <T extends keyof VehicleDataState>(
        key: T,
        value: VehicleDataState[T]
    ): Promise<void> => {
        const newData = { ...formState.vehicleData, [key]: value };

        if (key === 'inputSelection') {
            newData.make = undefined;
            newData.model = undefined;
            newData.poweredBy = undefined;
            newData.tsn = undefined;

            onMakeSelected(undefined);
        }

        if (key === 'make' && typeof value === 'string') {
            onMakeSelected(value);
            newData.model = undefined;
            newData.poweredBy = undefined;
        }

        if (
            (key === 'make' || key === 'model' || key === 'poweredBy') &&
            newData.make &&
            newData.model
        ) {
            if (newData.poweredBy && key === 'model') {
                newData.poweredBy = undefined;
            }

            onModelSelected(newData.make, newData.model, newData.poweredBy);
        }

        setErrors({ ...errors, [key]: undefined });
        updateFormState({ ...formState, vehicleData: newData });
    };

    const debouncedTsnChange = (value: string): void => {
        const newData = {
            ...formState,
            vehicleData: { ...formState.vehicleData, tsn: value.toUpperCase(), valid: undefined },
        };
        setErrors({ ...errors, tsn: undefined });
        updateFormState(newData);
        debounce(newData);
    };

    const validate = (): boolean => {
        let isValid = true;
        let scrollIntoView: React.RefObject<HTMLDivElement> | undefined = undefined;

        if (!formState.vehicleData.expectedMileage) {
            isValid = false;
            errors.expectedMileage = <React.Fragment />;
            scrollIntoView = fieldRefs.expectedMileage;
        } else {
            errors.expectedMileage = undefined;
        }

        if (!formState.vehicleData.paymentOption) {
            isValid = false;
            errors.paymentOption = <React.Fragment />;
            scrollIntoView = fieldRefs.paymentOption;
        } else {
            errors.paymentOption = undefined;
        }

        if (
            formState.vehicleData.firstRegistration &&
            formState.vehicleData.firstRegistration.isAfter(Date.now())
        ) {
            isValid = false;
            errors.firstRegistration = 'Muss in der Vergangenheit liegen.';
            scrollIntoView = fieldRefs.firstRegistration;
        } else {
            errors.firstRegistration = undefined;
        }

        if (formState.vehicleData.inputSelection === undefined) {
            isValid = false;
            errors.tsn = <React.Fragment />;
        } else if (formState.vehicleData.inputSelection === 'make') {
            if (!formState.vehicleData.tsn) {
                isValid = false;
                errors.model = <React.Fragment />;
                scrollIntoView = fieldRefs.model;
            } else {
                errors.model = undefined;
            }

            if (!formState.vehicleData.poweredBy) {
                isValid = false;
                errors.poweredBy = <React.Fragment />;
                scrollIntoView = fieldRefs.poweredBy;
            } else {
                errors.poweredBy = undefined;
            }

            if (!formState.vehicleData.model) {
                isValid = false;
                errors.model = <React.Fragment />;
                scrollIntoView = fieldRefs.model;
            } else {
                errors.model = undefined;
            }

            if (!formState.vehicleData.make) {
                isValid = false;
                errors.make = <React.Fragment />;
                scrollIntoView = fieldRefs.make;
            } else {
                errors.make = undefined;
            }
        } else {
            if (
                !formState.vehicleData.tsn ||
                formState.vehicleData.tsn.length !== 3 ||
                !formState.vehicleData.make ||
                !formState.vehicleData.model ||
                !formState.vehicleData.poweredBy ||
                !formState.vehicleData.buildYear
            ) {
                isValid = false;
                errors.tsn = 'Die angegebene TSN passt zu keinem Modell in der Datenbank.';
                scrollIntoView = fieldRefs.tsn;
            } else {
                errors.tsn = undefined;
            }
        }

        if (scrollIntoView?.current) {
            scrollIntoView.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }

        setErrors({ ...errors });
        return isValid;
    };

    const onFinish = async (): Promise<void> => {
        if (!validate()) {
            return;
        } else {
            proceed();
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Card className='view-card'>
            <h2>{'Fahrzeug auswählen'}</h2>
            <ExpandableSection
                isOpen={formState.vehicleData.inputSelection === 'make'}
                title='ÜBER MARKE UND MODELL'
                onClick={() => onChange('inputSelection', 'make')}
            >
                <FormField
                    ref={fieldRefs.make}
                    label='MARKE'
                    required
                    error={errors.make}
                    style='half-width'
                >
                    <Select
                        style={{ minWidth: '100%' }}
                        options={prefillOptions.manufacturerSelection.map((option) => {
                            return { label: option, value: option };
                        })}
                        value={formState.vehicleData.make}
                        onChange={(value) => onChange('make', value)}
                        status={errors.make ? 'error' : ''}
                    />
                </FormField>
                <FormField
                    ref={fieldRefs.model}
                    label='MODELL'
                    required
                    error={errors.model}
                    style='half-width'
                >
                    <Select
                        style={{ minWidth: '100%' }}
                        options={modelOptions.map((option) => {
                            return { label: option, value: option };
                        })}
                        value={formState.vehicleData.model}
                        onChange={(value) => onChange('model', value)}
                        status={errors.model ? 'error' : ''}
                    />
                </FormField>
                <FormField
                    ref={fieldRefs.poweredBy}
                    label='ANTRIEB'
                    required
                    error={errors.poweredBy}
                    style='half-width'
                >
                    <Select
                        style={{ minWidth: '100%' }}
                        options={poweredByOptions.map((option) => {
                            return { label: option, value: option };
                        })}
                        value={formState.vehicleData.poweredBy}
                        onChange={(value) => onChange('poweredBy', value)}
                        status={errors.poweredBy ? 'error' : ''}
                    />
                </FormField>
                {vehicleOptions.length > 0 &&
                    formState.vehicleData.make &&
                    formState.vehicleData.model &&
                    formState.vehicleData.poweredBy && (
                        <div>
                            <b>
                                {
                                    'Wir haben folgende Modelle gefunden. Bitte wählen Sie Ihr Fahrzeug aus:'
                                }
                            </b>
                            {vehicleOptions.map((option, index) => (
                                <VehicleCard
                                    key={index}
                                    vehicleType={option}
                                    selected={isSelectedVehicle(option, formState.vehicleData)}
                                    select={select}
                                    canSelect
                                />
                            ))}
                        </div>
                    )}
            </ExpandableSection>
            <ExpandableSection
                isOpen={formState.vehicleData.inputSelection === 'hsn-tsn'}
                title='MIT KFZ-SCHEIN (HSN/TSN)'
                onClick={() => onChange('inputSelection', 'hsn-tsn')}
            >
                <FormField label='Herstellernummer (4-stellige HSN)' style='half-width'>
                    <Input disabled readOnly={true} value={'1590 Jaguar Land Rover'} />
                </FormField>
                <FormField
                    ref={fieldRefs.tsn}
                    label='Typschlüsselnummer (3-stellige TSN)'
                    required
                    error={errors.tsn}
                    style='half-width'
                >
                    <Input
                        maxLength={3}
                        value={formState.vehicleData.tsn}
                        onChange={(event) => debouncedTsnChange(event.target.value)}
                        status={errors.tsn ? 'error' : ''}
                    />
                </FormField>
                {formState.vehicleData.valid === 'valid' &&
                formState.vehicleData.tsn &&
                formState.vehicleData.tsn.length === 3 &&
                !errors.tsn ? (
                    <>
                        <VehicleCard
                            vehicleType={{
                                manufacturer: formState.vehicleData.make ?? '',
                                modelcat: formState.vehicleData.model ?? '',
                                typ:
                                    formState.vehicleData.model?.replace(
                                        `${formState.vehicleData.make} `,
                                        ''
                                    ) ?? '',
                                constructionYear: formState.vehicleData.buildYear ?? '',
                                poweredBy: formState.vehicleData.poweredBy ?? '',
                                perfomance: formState.vehicleData.perfomance ?? '',
                                hsn: '1590',
                                tsn: formState.vehicleData.tsn,
                                status: formState.vehicleData.valid ?? 'invalid',
                            }}
                            selected
                        />
                    </>
                ) : (
                    <>
                        <div>
                            <span>
                                {
                                    'Zu finden auf der Vorderseite des Fahrzeugscheins (mittleres Blatt)'
                                }
                            </span>
                        </div>
                        <div style={{ marginBottom: '2.5rem' }}>
                            <img alt='papers-hint' src='/static/images/papers-hint.png' />
                        </div>
                    </>
                )}
            </ExpandableSection>
            <FormSection>
                <FormField
                    ref={fieldRefs.firstRegistration}
                    label='ERSTZULASSUNG (Bei Neufahrzeugen nicht auswählen)'
                    error={errors.firstRegistration}
                    style='full-width'
                >
                    <DatePicker
                        changeOnBlur
                        style={{ minWidth: '50%' }}
                        format={dateFormat}
                        value={formState.vehicleData.firstRegistration}
                        onChange={(value) => onChange('firstRegistration', value ?? undefined)}
                        status={errors.firstRegistration ? 'error' : ''}
                    />
                </FormField>
            </FormSection>
            <FormSection>
                <FormField
                    ref={fieldRefs.paymentOption}
                    label='Wie wird das Fahrzeug finanziert?'
                    required
                    error={errors.paymentOption}
                    style='half-width'
                >
                    <div className='vehicle-data-radio'>
                        <Radio
                            checked={formState.vehicleData.paymentOption === 'BARKAUF / KREDIT'}
                            onChange={() => onChange('paymentOption', 'BARKAUF / KREDIT')}
                        >
                            <span className='expander-header-title'>{'BARKAUF / KREDIT'}</span>
                        </Radio>
                    </div>
                    <div className='vehicle-data-radio'>
                        <Radio
                            checked={formState.vehicleData.paymentOption === 'LEASING'}
                            onChange={() => onChange('paymentOption', 'LEASING')}
                        >
                            <span className='expander-header-title'>{'LEASING'}</span>
                        </Radio>
                    </div>
                </FormField>
            </FormSection>
            <FormSection>
                <FormField
                    ref={fieldRefs.expectedMileage}
                    label='FAHRLEISTUNG'
                    required
                    error={errors.expectedMileage}
                    style='half-width'
                >
                    <Select
                        style={{ minWidth: '100%' }}
                        options={prefillOptions.mileageSelection.map((option) => {
                            return { label: option, value: option };
                        })}
                        value={formState.vehicleData.expectedMileage}
                        onChange={(value) => onChange('expectedMileage', value)}
                        status={errors.expectedMileage ? 'error' : ''}
                    />
                </FormField>
            </FormSection>
            <RequiredNotice />
            <FormNavigation recede={recede} proceed={onFinish} />
        </Card>
    );
};
