import React from 'react';
import './progress-breadcrumbs.scss';
import { SingleBar } from './single-bar';

type Props = {
    stage: number;
    gotoProgress: (stage: number) => void;
};

export const ProgressBreadcrumbs: React.FC<Props> = ({ stage, gotoProgress }: Props) => {
    return (
        <div className='progress-breadcrumbs container'>
            <div className='progress-breadcrumbs-header'>
                <h1>
                    {'OPTIMALE ABSICHERUNG'} <br />
                    {'FÜR IHR FAHRZEUG'}
                </h1>
                <h2>
                    {stage < 4
                        ? 'Nur noch wenige Schritte bis zu Ihrem Prämienangebot'
                        : stage < 7
                        ? 'Nur noch wenige Schritte bis zu Ihrem Versicherungsabschluss'
                        : ''}
                </h2>
            </div>
            <div className='progress-breadcrumbs-sections'>
                <SingleBar
                    title='Fahrzeug'
                    gridArea='first'
                    gotoProgress={() => stage < 7 && gotoProgress(1)}
                    active
                    current={stage === 1}
                    canClick={stage > 1 && stage < 7}
                />
                <SingleBar
                    title='Fahrer'
                    gridArea='second'
                    gotoProgress={() => stage < 7 && gotoProgress(2)}
                    active={stage >= 2}
                    current={stage === 2}
                    canClick={stage > 2 && stage < 7}
                />
                <SingleBar
                    title='Vorversicherung'
                    gridArea='third'
                    gotoProgress={() => stage < 7 && gotoProgress(3)}
                    active={stage >= 3}
                    current={stage === 3}
                    canClick={stage > 3 && stage < 7}
                />
                <SingleBar
                    title='Prämienangebot'
                    gridArea='fourth'
                    gotoProgress={() => stage < 7 && gotoProgress(4)}
                    active={stage >= 4}
                    current={stage === 4}
                    canClick={stage > 4 && stage < 7}
                />
                <SingleBar
                    title='Persönliche Daten'
                    gridArea='fifth'
                    gotoProgress={() => stage < 7 && gotoProgress(5)}
                    active={stage >= 5}
                    current={stage === 5}
                    canClick={stage > 5 && stage < 7}
                />
                <SingleBar
                    title='Übersicht'
                    gridArea='sixth'
                    gotoProgress={() => stage < 7 && gotoProgress(6)}
                    active={stage >= 6}
                    current={stage === 6}
                />
                <SingleBar
                    title='Versicherungs-abschluss'
                    gridArea='seventh'
                    gotoProgress={() => stage < 7 && gotoProgress(7)}
                    active={stage >= 7}
                    current={stage === 7}
                />
            </div>
        </div>
    );
};
