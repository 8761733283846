import { InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Card, Radio, Tooltip } from 'antd';
import Select from 'antd/es/select';
import React, { useEffect, useState } from 'react';
import { useAxios } from '../../../lib/axios';
import { Endpoints } from '../../../lib/shared/endpoints';
import { FormField, FormNavigation, FormSection, RequiredNotice } from '../../components';
import { PreviousInsuranceState } from '../../state';
import { ErrorState } from '../error-state';
import { ViewProps } from '../view-props';

type PrefillingOptions = {
    predamageSelection: readonly string[];
};

type Props = ViewProps & {
    options: PrefillingOptions;
};

export const PreviousInsuranceView: React.FC<Props> = ({
    options,
    formState,
    updateFormState,
    recede,
    proceed,
}: Props) => {
    const axios = useAxios();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [errors, setErrors] = useState<ErrorState<PreviousInsuranceState>>({});

    const onChange = async <T extends keyof PreviousInsuranceState>(
        key: T,
        value: PreviousInsuranceState[T]
    ): Promise<void> => {
        const newData = { ...formState.previousInsurance, [key]: value };
        setErrors({ ...errors, [key]: undefined });
        updateFormState({ ...formState, previousInsurance: newData });
    };

    const validate = (): boolean => {
        let isValid = true;
        if (formState.previousInsurance.terminated === undefined) {
            isValid = false;
            errors.terminated = <React.Fragment />;
        } else {
            errors.terminated = undefined;
        }

        if (!formState.previousInsurance.predamage) {
            isValid = false;
            errors.predamage = <React.Fragment />;
        } else {
            errors.predamage = undefined;
        }

        setErrors({ ...errors });
        return isValid;
    };

    const onFinish = (): void => {
        if (validate()) {
            proceed();
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const fetchPrefill = async (): Promise<void> => {
            setIsLoading(true);
            try {
                const response = await axios.get<PrefillingOptions>(Endpoints.PREFILLINGS);
                if (response.data && response.data.predamageSelection?.length > 0) {
                    onChange('predamage', response.data.predamageSelection[0]);
                }
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error(e);
            } finally {
                setIsLoading(false);
            }
        };

        fetchPrefill();
    }, []);

    return (
        <Card className='view-card'>
            {isLoading && <LoadingOutlined className='main-page-loading' />}
            {!isLoading && (
                <>
                    <FormSection>
                        <h3>{'Angaben zur Vorversicherung'}</h3>
                        <FormField
                            label='Wurde Ihre KFZ-Versicherung durch Ihren Vorversicherer gekündigt?'
                            required
                            error={errors.terminated}
                            style='binary-choice'
                        >
                            <Radio
                                checked={formState.previousInsurance.terminated}
                                onChange={() => onChange('terminated', true)}
                            >
                                {'Ja'}
                            </Radio>
                            <Radio
                                checked={!formState.previousInsurance.terminated}
                                onChange={() => onChange('terminated', false)}
                            >
                                {'Nein'}
                            </Radio>
                        </FormField>
                    </FormSection>
                    <FormSection>
                        <div
                            className={`form-field-label ${
                                errors.predamage ? ' form-field-error' : ''
                            }`}
                        >
                            <span>
                                {'Wie viele Schäden hatten Sie in den letzten 3 Jahren?'}
                                <span className='form-field-required'>*</span>
                                <div>
                                    {'(Ausgenommen Teilkaskoschäden) '}
                                    <Tooltip
                                        placement='top'
                                        arrow={false}
                                        title={
                                            <div>
                                                <span>
                                                    {
                                                        'Folgende Schadenereignisse zählen zur Teilkasko und sind hier nicht anzugeben:'
                                                    }
                                                </span>
                                                <ul>
                                                    <li>{'Brand und Explosion'}</li>
                                                    <li>{'Entwendung'}</li>
                                                    <li>
                                                        {
                                                            'Sturm, Hagel, Blitzschlag, Überschwemmung, Lawinen'
                                                        }
                                                    </li>
                                                    <li>{'Zusammenstoß mit Tieren'}</li>
                                                    <li>{'Glasbruch'}</li>
                                                    <li>{'Tierbissschäden'}</li>
                                                </ul>
                                            </div>
                                        }
                                    >
                                        <InfoCircleOutlined style={{ color: '#96122c' }} />
                                    </Tooltip>
                                </div>
                            </span>
                            <span className={`form-field-inline`}>
                                <Select
                                    style={{ minWidth: '20rem' }}
                                    options={options.predamageSelection.map((option) => {
                                        return { label: option, value: option };
                                    })}
                                    value={formState.previousInsurance.predamage}
                                    onChange={(value) => onChange('predamage', value)}
                                    status={errors.predamage ? 'error' : ''}
                                />
                            </span>
                        </div>
                        {errors.predamage && (
                            <div className='form-field-error'>{errors.predamage}</div>
                        )}
                    </FormSection>
                </>
            )}
            <RequiredNotice />
            <FormNavigation recede={recede} proceed={onFinish} />
        </Card>
    );
};
