import { Radio } from 'antd';
import React, { ReactNode } from 'react';
import './expandable-section.scss';

type Props = {
    isOpen: boolean;
    title: string;
    onClick: () => void | Promise<void>;
    children: ReactNode;
};

export const ExpandableSection: React.FC<Props> = ({ isOpen, title, onClick, children }: Props) => {
    const contentClasses = `expander-content${isOpen ? ' active' : ''}`;

    return (
        <div style={{ marginTop: '0.75rem' }}>
            <button className='expander-header' type='button' onClick={onClick}>
                <span>
                    <Radio className='expander-radio' checked={isOpen}>
                        <span className='expander-header-title'>{title}</span>
                    </Radio>
                </span>
            </button>
            <div className={contentClasses}>{children}</div>
        </div>
    );
};
