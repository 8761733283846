import { ArrowRightOutlined } from '@ant-design/icons';
import { Button, Card, Input } from 'antd';
import React, { ReactNode, useState } from 'react';
import { FormField, FormSection, RequiredNotice } from '../../components';
import '../../styles/common.scss';
import '../../styles/form.scss';

const dealerErrorMessage = (
    <span>
        {
            'Leider haben wir die angegebene Händlernummer nicht gefunden, bitte kontaktieren Sie uns unter:\n'
        }
        <br />
        <br />
        {'Tel.: +49 (0) 6173 33 66 99-2 oder per Mail an: '}
        <a href='mailto:kundenbetreuung@jlr-insurance.de'>{'kundenbetreuung@jlr-insurance.de'}</a>
    </span>
);

type Props = {
    onFormSubmit: (values: FormValues) => boolean | Promise<boolean>;
    gotoCustomerLogin: () => void;
};

type FormValues = {
    dealerNumber: string | undefined;
    sellerNumber: string | undefined;
};

export const DealerLoginView: React.FC<Props> = ({ onFormSubmit, gotoCustomerLogin }: Props) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [values, setValues] = useState<FormValues>({
        dealerNumber: undefined,
        sellerNumber: undefined,
    });
    const [error, setError] = useState<ReactNode>(null);

    const onChange = (element: HTMLInputElement): void => {
        if (element.name === 'dealerNumber') {
            setValues({ ...values, dealerNumber: element.value });
        } else if (element.name === 'sellerNumber') {
            setValues({ ...values, sellerNumber: element.value });
        }
    };

    const onFinish = async (): Promise<void> => {
        setIsLoading(true);
        if (!values.dealerNumber || !(await onFormSubmit(values))) {
            setError(dealerErrorMessage);
        } else {
            setError(null);
        }
        setIsLoading(false);
    };

    return (
        <Card className='view-card width-limited-card login-view'>
            <h3>{'Bitte geben Sie zur Verifizierung Ihre Händlernummer ein:'}</h3>
            <FormSection>
                <FormField label='HÄNDLERNUMMER' required error={error} style='full-width'>
                    <Input
                        className='dealerNumber-input'
                        name='dealerNumber'
                        maxLength={50}
                        value={values.dealerNumber}
                        onChange={(e) => onChange(e.target)}
                        status={error ? 'error' : ''}
                    />
                </FormField>
                <FormField
                    label='MAILADRESSE VERKAUFSBERATER'
                    required={false}
                    error={undefined}
                    style='full-width'
                >
                    <Input
                        className='sellerNumber-input'
                        name='sellerNumber'
                        maxLength={50}
                        value={values.sellerNumber}
                        onChange={(e) => onChange(e.target)}
                    />
                </FormField>
                <div>
                    <span>
                        {'Sind Sie Kunde? Versichern Sie sich '}
                        <u
                            role='presentation'
                            onClick={gotoCustomerLogin}
                            style={{ cursor: 'pointer' }}
                        >
                            {'hier'}
                        </u>
                    </span>
                </div>
            </FormSection>
            <RequiredNotice />
            <div className='flex-container'>
                <div style={{ flexGrow: 1 }}></div>
                <Button
                    className='navigation-button'
                    type='primary'
                    onClick={onFinish}
                    loading={isLoading}
                >
                    <span>
                        {'Anmelden'}
                        <ArrowRightOutlined className='arrow-right' />
                    </span>
                </Button>
            </div>
        </Card>
    );
};
