import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';

type Props = {
    proceedText?: string | undefined;
    recede: () => void | Promise<void>;
    proceed: () => void | Promise<void>;
};

export const FormNavigation: React.FC<Props> = ({ proceedText, recede, proceed }: Props) => {
    return (
        <div style={{ display: 'flex' }}>
            <Button className='navigation-button-secondary' type='text' onClick={recede}>
                <ArrowLeftOutlined className='arrow-left' />
                {'Zurück'}
            </Button>
            <div style={{ flexGrow: 1 }} />
            <Button className='navigation-button' type='primary' onClick={proceed}>
                {proceedText ?? 'Weiter'}
                <ArrowRightOutlined className='arrow-right' />
            </Button>
        </div>
    );
};
