import React from 'react';
import './progress-breadcrumbs.scss';

type Props = {
    title: string;
    gridArea: string;
    active: boolean;
    current?: boolean;
    canClick?: boolean;
    gotoProgress: () => false | void;
};

export const SingleBar: React.FC<Props> = ({
    title,
    gridArea,
    active,
    current,
    canClick,
    gotoProgress,
}: Props) => {
    return (
        <div
            className={`progress-breadcrumbs-section progress-breadcrumbs-section-${gridArea}${
                active ? ' active' : ''
            }${canClick ? ' clickable' : ''}`}
            role='presentation'
            onClick={canClick ? gotoProgress : undefined}
        >
            <span className='progress-breadcrumbs-section bar'></span>
            <div className={`progress-breadcrumbs-section title${current ? ' current' : ''}`}>
                {title}
            </div>
        </div>
    );
};
