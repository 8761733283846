import { Card, DatePicker, Input, Radio, Select } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { useAxios } from '../../../lib/axios';
import { dateFormat } from '../../../lib/shared/date-format';
import { Endpoints } from '../../../lib/shared/endpoints';
import { SearchCityResponseDto } from '../../../lib/shared/types';
import { useDebounce } from '../../../lib/shared/useDebounce';
import { FormField, FormNavigation, FormSection, RequiredNotice } from '../../components';
import { DriverDataState, FormState } from '../../state';
import { ErrorState } from '../error-state';
import { FormFieldRefs } from '../form-field-refs';
import { ViewProps } from '../view-props';
import './driver-data-view.scss';

type PrefillingOptions = {
    paymentMethodSelection: readonly string[];
};

type Props = ViewProps & {
    options: PrefillingOptions;
};

export const DriverDataView: React.FC<Props> = ({
    options,
    formState,
    updateFormState,
    recede,
    proceed,
}: Props) => {
    const axios = useAxios();

    const [errors, setErrors] = useState<ErrorState<DriverDataState>>({});

    const fieldRefs: FormFieldRefs<DriverDataState> = {
        postalCode: useRef<HTMLDivElement>(null),
        city: useRef<HTMLDivElement>(null),
        dateOfBirth: useRef<HTMLDivElement>(null),
        paymentMethod: useRef<HTMLDivElement>(null),
        youngDriver: useRef<HTMLDivElement>(null),
        dateOfBirthYoungest: useRef<HTMLDivElement>(null),
        happySurance: useRef<HTMLDivElement>(null),
    };

    const validatePostalCode = async (state: FormState): Promise<FormState> => {
        if (
            !Number.isNaN(state.driverData.postalCode) &&
            state.driverData.postalCode?.length === 5
        ) {
            const newData = { ...state.driverData };
            try {
                const response = await axios.get<SearchCityResponseDto>(
                    `${Endpoints.SEARCHCITY}?postalCode=${newData.postalCode}`
                );
                if (response.data && response.data.status === 'valid') {
                    newData.cityOptions = response.data.city;
                    if (!response.data.city.some((city) => city === newData.city)) {
                        newData.city = response.data.city[0];
                    }
                } else {
                    newData.cityOptions = [];
                    newData.city = undefined;
                }
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error(e);
                newData.cityOptions = [];
                newData.city = undefined;
            }

            const result = { ...state, driverData: newData };
            updateFormState(result);
            return result;
        } else {
            const newData = { ...state.driverData };
            newData.cityOptions = [];
            newData.city = undefined;
            const result = { ...state, driverData: newData };
            updateFormState(result);
            return result;
        }
    };

    const [debounce] = useDebounce(validatePostalCode, 500);

    const onChange = async <T extends keyof DriverDataState>(
        key: T,
        value: DriverDataState[T]
    ): Promise<void> => {
        const newData = { ...formState.driverData, [key]: value };

        if (key === 'dateOfBirth') {
            let dateValue = value as dayjs.Dayjs;
            if (dateValue) {
                dateValue = dateValue.startOf('day');
                dateValue = dateValue.add(dateValue.utcOffset(), 'minutes');
                newData.dateOfBirth = dateValue;
            }
        }

        if (key === 'dateOfBirthYoungest') {
            let dateValue = value as dayjs.Dayjs;
            if (dateValue) {
                dateValue = dateValue.startOf('day');
                dateValue = dateValue.add(dateValue.utcOffset(), 'minutes');
                newData.dateOfBirthYoungest = dateValue;
            }
        }

        setErrors({ ...errors, [key]: undefined });
        updateFormState({ ...formState, driverData: newData });
    };

    const debouncedPostalCodeChange = (value: string): void => {
        if (value && !/^\d+$/.test(value)) {
            if (!formState.driverData.postalCode) {
                const newData = { ...formState.driverData, postalCode: '' };
                updateFormState({ ...formState, driverData: newData });
            }

            return;
        }

        const newData = {
            ...formState,
            driverData: { ...formState.driverData, postalCode: value },
        };
        setErrors({ ...errors, postalCode: undefined });
        updateFormState(newData);
        debounce(newData);
    };

    const validate = async (): Promise<boolean> => {
        const state = await validatePostalCode(formState);
        let isValid = true;
        let scrollIntoView: React.RefObject<HTMLDivElement> | undefined = undefined;

        if (state.driverData.youngDriver === true && !state.driverData.dateOfBirthYoungest) {
            isValid = false;
            errors.dateOfBirthYoungest = <React.Fragment />;
            scrollIntoView = fieldRefs.dateOfBirthYoungest;
        } else if (
            state.driverData.youngDriver === true &&
            state.driverData.dateOfBirthYoungest &&
            state.driverData.dateOfBirthYoungest.add(18, 'years').isAfter(dayjs(Date.now()), 'days')
        ) {
            isValid = false;
            errors.dateOfBirthYoungest = 'Jüngster Fahrer muss mindestens 18 Jahre alt sein';
            scrollIntoView = fieldRefs.dateOfBirthYoungest;
        } else {
            errors.dateOfBirthYoungest = undefined;
        }

        if (state.driverData.youngDriver === undefined) {
            isValid = false;
            errors.youngDriver = <React.Fragment />;
            scrollIntoView = fieldRefs.youngDriver;
        } else {
            errors.youngDriver = undefined;
        }

        if (!state.driverData.paymentMethod) {
            isValid = false;
            errors.paymentMethod = <React.Fragment />;
            scrollIntoView = fieldRefs.paymentMethod;
        } else {
            errors.paymentMethod = undefined;
        }

        if (!state.driverData.dateOfBirth) {
            isValid = false;
            errors.dateOfBirth = <React.Fragment />;
            scrollIntoView = fieldRefs.dateOfBirth;
        } else if (
            state.driverData.dateOfBirth.add(18, 'years').isAfter(dayjs(Date.now()), 'days')
        ) {
            isValid = false;
            errors.dateOfBirth = 'Halter muss mindestens 18 Jahre alt sein';
            scrollIntoView = fieldRefs.dateOfBirth;
        } else {
            errors.dateOfBirth = undefined;
        }

        if (
            !state.driverData.postalCode ||
            state.driverData.postalCode.length != 5 ||
            !state.driverData.city
        ) {
            isValid = false;
            errors.postalCode = <React.Fragment />;
            scrollIntoView = fieldRefs.postalCode;
        } else {
            errors.postalCode = undefined;
        }

        if (scrollIntoView?.current) {
            scrollIntoView.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }

        setErrors({ ...errors });
        return isValid;
    };

    const onFinish = async (): Promise<void> => {
        if (await validate()) {
            proceed();
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Card className='view-card'>
            <h3>{'Versicherungsnehmer'}</h3>
            <FormSection>
                <div className='driver-data-half-split'>
                    <div>
                        <FormField
                            ref={fieldRefs.postalCode}
                            label='POSTLEITZAHL'
                            required
                            error={errors.postalCode}
                            style='full-width'
                        >
                            <Input
                                maxLength={5}
                                value={formState.driverData.postalCode}
                                onChange={(event) => debouncedPostalCodeChange(event.target.value)}
                                status={errors.postalCode ? 'error' : ''}
                            />
                        </FormField>
                    </div>
                    <div>
                        <FormField ref={fieldRefs.city} label='ORT' style='full-width'>
                            <Select
                                style={{ minWidth: '100%' }}
                                value={formState.driverData.city}
                                options={
                                    formState.driverData.cityOptions
                                        ? formState.driverData.cityOptions.map((option) => {
                                              return { label: option, value: option };
                                          })
                                        : []
                                }
                                onChange={(value) => onChange('city', value)}
                                disabled={
                                    !formState.driverData.cityOptions ||
                                    formState.driverData.cityOptions.length === 0
                                }
                            />
                        </FormField>
                    </div>
                </div>
            </FormSection>
            <FormSection>
                <div className='driver-data-half-split'>
                    <div>
                        <FormField
                            ref={fieldRefs.dateOfBirth}
                            label='GEBURTSDATUM'
                            required
                            error={errors.dateOfBirth}
                            style='full-width'
                        >
                            <DatePicker
                                changeOnBlur
                                style={{ minWidth: '100%' }}
                                format={dateFormat}
                                value={formState.driverData.dateOfBirth}
                                onChange={(value) => onChange('dateOfBirth', value ?? undefined)}
                                status={errors.dateOfBirth ? 'error' : ''}
                            />
                        </FormField>
                    </div>
                </div>
            </FormSection>
            <FormSection>
                <div className='driver-data-half-split'>
                    <div>
                        <FormField
                            ref={fieldRefs.paymentMethod}
                            label='GEWÜNSCHTE ZAHLWEISE DER PRÄMIE'
                            required
                            error={errors.paymentMethod}
                            style='full-width'
                        >
                            <Select
                                style={{ minWidth: '100%' }}
                                options={options.paymentMethodSelection.map((option) => {
                                    return { label: option, value: option };
                                })}
                                value={formState.driverData.paymentMethod}
                                onChange={(value) => onChange('paymentMethod', value)}
                                status={errors.paymentMethod ? 'error' : ''}
                            />
                        </FormField>
                    </div>
                </div>
            </FormSection>
            <h3>{'Weitere Fahrer'}</h3>
            <FormSection>
                <FormField
                    ref={fieldRefs.youngDriver}
                    label='Gibt es Fahrer unter 25 Jahre (ausgenommen Versicherungsnehmer)?'
                    required
                    error={errors.youngDriver}
                    style='binary-choice'
                >
                    <Radio
                        checked={formState.driverData.youngDriver}
                        onChange={() => onChange('youngDriver', true)}
                    >
                        {'Ja'}
                    </Radio>
                    <Radio
                        checked={!formState.driverData.youngDriver}
                        onChange={() => onChange('youngDriver', false)}
                    >
                        {'Nein'}
                    </Radio>
                </FormField>
                {formState.driverData.youngDriver && (
                    <div className='driver-data-half-split'>
                        <div>
                            <FormField
                                ref={fieldRefs.dateOfBirthYoungest}
                                label='GEBURTSDATUM JÜNGSTER FAHRER'
                                required
                                error={errors.dateOfBirthYoungest}
                                style='full-width'
                            >
                                <DatePicker
                                    changeOnBlur
                                    style={{ minWidth: '100%' }}
                                    format={dateFormat}
                                    value={formState.driverData.dateOfBirthYoungest}
                                    onChange={(value) =>
                                        onChange('dateOfBirthYoungest', value ?? undefined)
                                    }
                                    status={errors.dateOfBirthYoungest ? 'error' : ''}
                                />
                            </FormField>
                        </div>
                    </div>
                )}
            </FormSection>
            <RequiredNotice />
            <FormNavigation recede={recede} proceed={onFinish} />
        </Card>
    );
};
