/* eslint-disable react/jsx-key */
import { Divider, Radio } from 'antd';
import { Property } from 'csstype';
import React, { ReactNode } from 'react';
import { RateDto } from '../../../../../lib/shared/types';
import './offer-selection-table.scss';

const tableHeaders: ReactNode[] = [
    <span className='offer-selection-card-name' style={{ color: 'black' }}>
        <b>{'Leistungen'}</b>
    </span>,
    <span>{'Selbstbeteiligung VK/TK'}</span>,
    <span>{'Fester Beitrag unabhängig von der Fahrleistung & Schadenfreiheitsklasse'}</span>,
    <span>
        {'Reparatur in einer zertifizierten Land Rover Werkstatt - möglichst bei Ihrem Partner'}
    </span>,
    <div>
        <div>{'Neupreisentschädigung nach Erstzulassung bei'}</div>
        <ul className='offer-selection-table-list'>
            <li>
                <b>{'Totalschaden'}</b>
            </li>
            <li>
                <b>{'Diebstahl'}</b>
            </li>
        </ul>
    </div>,
    <span>{'Uneingeschränkter Fahrerkreis'}</span>,
    <span>{'Keine Beitragserhöhung im Schadenfall'}</span>,
    <span>{'Kraftfahrzeug-Schutzbrief bei Panne & Unfall'}</span>,
    <span>{'Erweiterung der Wildschadendeckung auf Tiere aller Art'}</span>,
    <span>{'Erweiterung der Deckung auf Fähren & Schiffe'}</span>,
    <span>{'Deckung für Schäden bei Lawinen & Muren'}</span>,
    <span>
        {'Weitgehender Verzicht auf den Einwand der groben Fahrlässigkeit im Kaskoschadenfall'}
    </span>,
    <span>{'Einschluss von Betriebsschäden'}</span>,
];

type Props = {
    dataRows: readonly RateDto[];
    selected: RateDto;
    select: (rate: RateDto) => void;
};

type CellProps = {
    column: number;
    row: number;
    withDivider?: boolean;
    align?: 'start' | 'center' | 'end';
    children?: ReactNode;
};

const Cell: React.FC<CellProps> = ({ column, row, withDivider, align, children }: CellProps) => {
    return (
        <div
            className='offer-selection-table-cell'
            style={{
                gridColumn: `${column} / ${column}`,
                gridRow: `${row} / ${row}`,
                zIndex: 3,
                justifyContent: align ?? 'end',
            }}
        >
            {children}
            <Divider
                className={
                    withDivider
                        ? 'offer-selection-table-divider'
                        : 'offer-selection-table-placeholder'
                }
            />
        </div>
    );
};

const generateGridAreas = (rows: readonly RateDto[]): Property.GridTemplateAreas => {
    const row = "'." + rows.map((_, index) => ` option${index}`).join('') + "'";
    const recommended =
        "'. " +
        rows.map((_, index) => (rows.length - 1 === index ? 'recommended' : '.')).join(' ') +
        "'";
    return [
        recommended,
        row,
        row,
        row,
        row,
        row,
        row,
        row,
        row,
        row,
        row,
        row,
        row,
        row,
        row,
        row,
        row,
    ].join(' ');
};

export const OfferSelectionTable: React.FC<Props> = ({ dataRows, selected, select }: Props) => {
    const areas = generateGridAreas(dataRows);

    return (
        <div className='offer-selection-table-container'>
            <div
                className='offer-selection-table'
                style={{
                    gridTemplateAreas: areas,
                    gridTemplateColumns: `repeat(${dataRows.length + 1}, 1fr)`,
                }}
            >
                <div className='offer-selection-recommended'>
                    <span style={{ gridArea: 'one' }}>{'UNSERE'}</span>
                    <span style={{ gridArea: 'two' }}>{'EMPFEHLUNG'}</span>
                </div>
                {tableHeaders.map((header, index) => {
                    return (
                        <Cell
                            column={1}
                            row={index + 5}
                            withDivider={index < tableHeaders.length - 1}
                            align={index < tableHeaders.length - 1 ? 'end' : 'start'}
                        >
                            {header}
                        </Cell>
                    );
                })}
                {dataRows.map((row, index) => {
                    return (
                        <>
                            <Cell column={index + 2} row={2} align='center'>
                                <Radio
                                    className='offer-selection-card-radio'
                                    checked={selected === row}
                                    onChange={() => select(row)}
                                ></Radio>
                            </Cell>
                            <Cell column={index + 2} row={3} align='center'>
                                <span className='offer-selection-card-name'>{row.rateName}</span>
                            </Cell>
                            <Cell column={index + 2} row={4} align='center'>
                                <span className='offer-selection-card-rate'>{row.rate}</span>
                            </Cell>
                            <Cell column={index + 2} row={5} withDivider>
                                <span className='offer-selection-card-name'>
                                    {row.paymentMethode}
                                </span>
                            </Cell>
                            <Cell column={index + 2} row={6} withDivider>
                                <span className='offer-selection-card-deductable'>
                                    {row.deductible}
                                </span>
                            </Cell>
                            <Cell column={index + 2} row={7} withDivider>
                                {row.independentOfNoclaimsClass && (
                                    <div className='offer-selection-card-check' />
                                )}
                            </Cell>
                            <Cell column={index + 2} row={8} withDivider>
                                {row.certifiedLandRoverWorkshop && (
                                    <div className='offer-selection-card-check' />
                                )}
                            </Cell>
                            <Cell column={index + 2} row={9} withDivider>
                                <div
                                    style={{
                                        marginBottom: '0.75rem',
                                    }}
                                >
                                    {row.newPriceCompensation.map((item) => {
                                        return (
                                            <div>
                                                <b>{item}</b>
                                            </div>
                                        );
                                    })}
                                </div>
                            </Cell>
                            <Cell column={index + 2} row={10} withDivider>
                                {row.unrestrictedDriverGroup && (
                                    <div className='offer-selection-card-check' />
                                )}
                            </Cell>
                            <Cell column={index + 2} row={11} withDivider>
                                {row.noPremiumIncrease && (
                                    <div className='offer-selection-card-check' />
                                )}
                            </Cell>
                            <Cell column={index + 2} row={12} withDivider>
                                {row.protectionLetter && (
                                    <div className='offer-selection-card-check' />
                                )}
                            </Cell>
                            <Cell column={index + 2} row={13} withDivider>
                                {row.wildlifeDamageCoverage && (
                                    <div className='offer-selection-card-check' />
                                )}
                            </Cell>
                            <Cell column={index + 2} row={14} withDivider>
                                {row.ferryAndShipsExtendedCover && (
                                    <div className='offer-selection-card-check' />
                                )}
                            </Cell>
                            <Cell column={index + 2} row={15} withDivider>
                                {row.avalanchesAndMudslidesCover && (
                                    <div className='offer-selection-card-check' />
                                )}
                            </Cell>
                            <Cell column={index + 2} row={16} withDivider>
                                {row.extensiveWaiverOfObjection && (
                                    <div className='offer-selection-card-check' />
                                )}
                            </Cell>
                            <Cell column={index + 2} row={17} align='start'>
                                {row.operationalDamage && (
                                    <div className='offer-selection-card-check' />
                                )}
                            </Cell>

                            <div
                                className={`offer-selection-card${
                                    selected === row ? ' selected' : ''
                                }`}
                                style={{ gridArea: `option${index}` }}
                            />

                            <div
                                style={{ cursor: 'pointer', gridArea: `option${index}`, zIndex: 4 }}
                                role='presentation'
                                onClick={() => select(row)}
                            />
                        </>
                    );
                })}
            </div>
        </div>
    );
};
