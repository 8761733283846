import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AxiosProvider } from './lib/axios';
import { App } from './app';
import { ConfigProvider } from 'antd';
import 'dayjs/locale/de';
import de from 'antd/locale/de_DE';

export const AppWithProviders = (): React.ReactElement => {
    return (
        <AxiosProvider>
            <BrowserRouter>
                <ConfigProvider locale={de}>
                    <App />
                </ConfigProvider>
            </BrowserRouter>
        </AxiosProvider>
    );
};

export default AppWithProviders;
