import { AxiosInstance } from 'axios';
import React, { createContext, useContext, useMemo } from 'react';
import { createAxiosInstance } from './initialize-axios';

type Props = {
    children?: React.ReactElement;
};

export function AxiosProvider(props: Props): JSX.Element {
    const axios = useMemo<AxiosInstance>(createAxiosInstance, []);

    return <AxiosContext.Provider value={axios}>{props.children}</AxiosContext.Provider>;
}

export const AxiosContext = createContext<AxiosInstance | null>(null);

export function useAxios(): AxiosInstance {
    const axios = useContext(AxiosContext);
    if (axios === null)
        throw new Error('AxiosContext.Provider is not set in the React component tree.');
    return axios;
}
