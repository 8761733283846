import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Routing } from './routing';
import { MainPage } from '../main-layout';

export const RoutedContent = (): React.ReactElement | null => {
    return (
        <Routes>
            <Route path={Routing.main} element={<MainPage />} />

            <Route path='*' element={<Navigate to={Routing.main} replace />} />
        </Routes>
    );
};
