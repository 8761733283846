export enum Endpoints {
    LOGIN = '/Login',
    PREFILLINGS = '/Prefillings',
    MODELLIST = '/Modellist',
    SEARCHMODEL = '/SearchModel',
    SEARCHCITY = '/SearchCity',
    IBANVALIDATION = '/IbanValidation',
    INSURANCERATE = '/InsuranceRate',
    CUSTOMER = '/Customer',
}
