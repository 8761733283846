import { Card, Input, Radio, Typography } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useAxios } from '../../../lib/axios';
import { Endpoints } from '../../../lib/shared/endpoints';
import { useDebounce } from '../../../lib/shared/useDebounce';
import { FormField, FormNavigation, FormSection, RequiredNotice } from '../../components';
import { FormState, PersonalDataState } from '../../state';
import { ErrorState } from '../error-state';
import { FormFieldRefs } from '../form-field-refs';
import { ViewProps } from '../view-props';
import './personal-data-view.scss';

enum Classification {
    Private = 'PRIVAT',
    Commercial = 'FIRMA',
}

const emailRegex = new RegExp(
    // eslint-disable-next-line no-control-regex
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
);

export const PersonalDataView: React.FC<ViewProps> = ({
    formState,
    updateFormState,
    recede,
    proceed,
}: ViewProps) => {
    const axios = useAxios();

    const [errors, setErrors] = useState<ErrorState<PersonalDataState>>({});

    const fieldRefs: FormFieldRefs<PersonalDataState> = {
        classification: useRef<HTMLDivElement>(null),
        companyName: useRef<HTMLDivElement>(null),
        firstName: useRef<HTMLDivElement>(null),
        lastName: useRef<HTMLDivElement>(null),
        street: useRef<HTMLDivElement>(null),
        houseNumber: useRef<HTMLDivElement>(null),
        email: useRef<HTMLDivElement>(null),
        iban: useRef<HTMLDivElement>(null),
    };

    const validateIban = async (state: FormState): Promise<FormState> => {
        const newData = { ...state.personalData };
        if (!newData.iban || newData.iban.length < 21) {
            newData.bank = undefined;
            const result = { ...state, personalData: newData };
            updateFormState(result);
            return result;
        }

        try {
            const response = await axios.get(`${Endpoints.IBANVALIDATION}?Iban=${newData.iban}`);
            if (response.data && response.data.status === 'valid') {
                newData.bank = response.data.bank;
                errors.iban = undefined;
            } else {
                newData.bank = undefined;
                errors.iban = <React.Fragment />;
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            errors.iban = <React.Fragment />;
            return state;
        } finally {
            setErrors({ ...errors });
        }
        const result = { ...state, personalData: newData };
        updateFormState(result);
        return result;
    };

    const [debounce] = useDebounce(validateIban, 500);

    const onChange = async <T extends keyof PersonalDataState>(
        key: T,
        value: PersonalDataState[T]
    ): Promise<void> => {
        if (key === 'houseNumber' && value && !/^\d+$/.test(value)) {
            if (!formState.personalData.houseNumber) {
                const newData = { ...formState.personalData, houseNumber: '' };
                updateFormState({ ...formState, personalData: newData });
            }

            return;
        }

        if (key === 'phoneNumber' && value) {
            for (let index = 0; index < value.length; index++) {
                const char = value.charAt(index);
                if (!/^[\d\\(\\)\- \\+\\/]$/.test(char)) {
                    if (!formState.personalData.phoneNumber) {
                        const newData = { ...formState.personalData, phoneNumber: '' };
                        updateFormState({ ...formState, personalData: newData });
                    }

                    return;
                }
            }
        }

        const newData = { ...formState.personalData, [key]: value };
        setErrors({ ...errors, [key]: undefined });
        updateFormState({ ...formState, personalData: newData });
    };

    const debouncedIbanChange = (value: string): void => {
        if (value && !/^[a-zA-Z0-9]+$/.test(value)) {
            if (!formState.personalData.iban) {
                const newData = { ...formState.personalData, iban: '' };
                updateFormState({ ...formState, personalData: newData });
            }

            return;
        }

        const newData = { ...formState, personalData: { ...formState.personalData, iban: value } };
        setErrors({ ...errors, iban: undefined });
        updateFormState(newData);
        debounce(newData);
    };

    const validate = (state: FormState): boolean => {
        let isValid = true;
        let scrollIntoView: React.RefObject<HTMLDivElement> | undefined = undefined;

        if (!state.personalData.iban || !state.personalData.bank) {
            isValid = false;
            errors.iban = <React.Fragment />;
            scrollIntoView = fieldRefs.iban;
        } else {
            errors.iban = undefined;
        }

        if (!state.personalData.email || !emailRegex.test(state.personalData.email)) {
            isValid = false;
            errors.email = 'Bitte eine gültige E-Mail-Adresse eingeben';
            scrollIntoView = fieldRefs.email;
        } else {
            errors.email = undefined;
        }

        if (!state.personalData.houseNumber) {
            isValid = false;
            errors.houseNumber = <React.Fragment />;
            scrollIntoView = fieldRefs.houseNumber;
        } else {
            errors.houseNumber = undefined;
        }

        if (!state.personalData.street) {
            isValid = false;
            errors.street = <React.Fragment />;
            scrollIntoView = fieldRefs.street;
        } else {
            errors.street = undefined;
        }

        if (!state.personalData.lastName) {
            isValid = false;
            errors.lastName = <React.Fragment />;
            scrollIntoView = fieldRefs.lastName;
        } else {
            errors.lastName = undefined;
        }

        if (!state.personalData.firstName) {
            isValid = false;
            errors.firstName = <React.Fragment />;
            scrollIntoView = fieldRefs.firstName;
        } else {
            errors.firstName = undefined;
        }

        if (
            state.personalData.classification === Classification.Commercial &&
            !state.personalData.companyName
        ) {
            isValid = false;
            errors.companyName = <React.Fragment />;
            scrollIntoView = fieldRefs.companyName;
        } else {
            errors.companyName = undefined;
        }

        if (state.personalData.classification === undefined) {
            isValid = false;
            errors.classification = <React.Fragment />;
        } else {
            errors.classification = undefined;
        }

        if (scrollIntoView?.current) {
            scrollIntoView.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }

        setErrors({ ...errors });
        return isValid;
    };

    const onFinish = async (): Promise<void> => {
        const state = await validateIban(formState);
        if (validate(state)) {
            proceed();
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Card className='view-card'>
            <FormSection>
                <div style={{ marginBottom: '1.5rem' }}>
                    <span>
                        <Radio
                            checked={
                                formState.personalData.classification === Classification.Private
                            }
                            onChange={() => onChange('classification', Classification.Private)}
                        >
                            {'PRIVAT'}
                        </Radio>
                        <Radio
                            checked={
                                formState.personalData.classification === Classification.Commercial
                            }
                            onChange={() => onChange('classification', Classification.Commercial)}
                        >
                            {'GEWERBLICH'}
                        </Radio>
                    </span>
                </div>
                {formState.personalData.classification === Classification.Commercial && (
                    <FormField
                        ref={fieldRefs.companyName}
                        label='FIRMA'
                        required
                        error={errors.companyName}
                        style='full-width'
                    >
                        <Input
                            maxLength={100}
                            value={formState.personalData.companyName}
                            onChange={(event) => onChange('companyName', event.target.value)}
                            status={errors.companyName ? 'error' : ''}
                        />
                    </FormField>
                )}
                <div className='personal-data-two-split'>
                    <div>
                        <FormField
                            ref={fieldRefs.firstName}
                            label='VORNAME'
                            required
                            error={errors.firstName}
                            style='full-width'
                        >
                            <Input
                                maxLength={40}
                                value={formState.personalData.firstName}
                                onChange={(event) => onChange('firstName', event.target.value)}
                                status={errors.firstName ? 'error' : ''}
                            />
                        </FormField>
                    </div>
                    <div>
                        <FormField
                            ref={fieldRefs.lastName}
                            label='NACHNAME'
                            required
                            error={errors.lastName}
                            style='full-width'
                        >
                            <Input
                                maxLength={40}
                                value={formState.personalData.lastName}
                                onChange={(event) => onChange('lastName', event.target.value)}
                                status={errors.lastName ? 'error' : ''}
                            />
                        </FormField>
                    </div>
                </div>
                <div className='personal-data-two-split'>
                    <div>
                        <FormField
                            ref={fieldRefs.street}
                            label='STRAẞE'
                            required
                            error={errors.street}
                            style='full-width'
                        >
                            <Input
                                maxLength={100}
                                value={formState.personalData.street}
                                onChange={(event) => onChange('street', event.target.value)}
                                status={errors.street ? 'error' : ''}
                            />
                        </FormField>
                    </div>
                    <div className='personal-data-two-split' style={{ columnGap: '0.75rem' }}>
                        <div>
                            <FormField
                                ref={fieldRefs.houseNumber}
                                label='HAUSNUMMER'
                                required
                                error={errors.houseNumber}
                                style='full-width'
                            >
                                <Input
                                    maxLength={5}
                                    value={formState.personalData.houseNumber}
                                    onChange={(event) =>
                                        onChange('houseNumber', event.target.value)
                                    }
                                    status={errors.houseNumber ? 'error' : ''}
                                />
                            </FormField>
                        </div>
                        <div>
                            <FormField label='ADDRESSZUSATZ' required={false} style='full-width'>
                                <Input
                                    maxLength={10}
                                    value={formState.personalData.addressExtension}
                                    onChange={(event) =>
                                        onChange('addressExtension', event.target.value)
                                    }
                                />
                            </FormField>
                        </div>
                    </div>
                </div>
                <div className='personal-data-two-split'>
                    <div>
                        <FormField label='POSTLEITZAHL' style='full-width'>
                            <Typography>{formState.driverData?.postalCode}</Typography>
                        </FormField>
                    </div>
                    <div>
                        <FormField label='ORT' style='full-width'>
                            <Typography>{formState.driverData?.city}</Typography>
                        </FormField>
                    </div>
                </div>
                <div className='personal-data-two-split'>
                    <div>
                        <FormField
                            ref={fieldRefs.email}
                            label='E-MAIL'
                            required
                            error={errors.email}
                            style='full-width'
                        >
                            <Input
                                maxLength={80}
                                value={formState.personalData.email}
                                disabled={!!formState.user.email}
                                readOnly={!!formState.user.email}
                                onChange={(event) => onChange('email', event.target.value)}
                                status={errors.email ? 'error' : ''}
                            />
                        </FormField>
                    </div>
                    <div>
                        <FormField label='TELEFON' style='full-width'>
                            <Input
                                maxLength={35}
                                value={formState.personalData.phoneNumber}
                                onChange={(event) => onChange('phoneNumber', event.target.value)}
                            />
                        </FormField>
                    </div>
                </div>
            </FormSection>
            <FormSection>
                <h3>{'ZAHLUNG SEPA-LASTSCHRIFT'}</h3>
                <h4>{'Der Versicherungsnehmer muss auch Kontoinhaber sein.'}</h4>
                <FormField
                    ref={fieldRefs.iban}
                    label='IBAN'
                    required
                    error={errors.iban}
                    style='full-width'
                >
                    <Input
                        maxLength={22}
                        value={formState.personalData.iban}
                        onChange={(event) => debouncedIbanChange(event.target.value)}
                        status={errors.iban ? 'error' : ''}
                    />
                </FormField>
                <FormField label='BANK' style='full-width'>
                    <Input readOnly disabled value={formState.personalData.bank} />
                </FormField>
            </FormSection>
            <RequiredNotice />
            <FormNavigation recede={recede} proceed={onFinish} />
        </Card>
    );
};
