import React, { ReactNode, forwardRef } from 'react';

type FormFieldStyle = 'half-width' | 'full-width' | 'binary-choice' | 'inline';

type Props = {
    label: string;
    required?: boolean;
    error?: ReactNode;
    style: FormFieldStyle;
    children: ReactNode;
};

export const FormField: React.ForwardRefExoticComponent<
    Props & React.RefAttributes<HTMLDivElement>
> = forwardRef(
    (
        { label, required, error, style, children }: Props,
        ref: React.ForwardedRef<HTMLDivElement>
    ) => {
        let classes: string;
        switch (style) {
            case 'half-width':
                classes = 'form-field-half';
                break;
            case 'full-width':
                classes = 'form-field-full';
                break;
            default:
                classes = '';
                break;
        }

        if (error) {
            classes += ' form-field-error';
        }

        if (style === 'binary-choice' || style === 'inline') {
            return (
                <>
                    <div className={`form-field-label ${classes}`} ref={ref}>
                        <span>{label}</span>
                        {required && <span className='form-field-required'>*</span>}
                        <span className={`form-field-${style}`}>{children}</span>
                    </div>
                    {error && <div className={classes}>{error}</div>}
                </>
            );
        } else {
            return (
                <>
                    <div className={`form-field-label ${classes}`} ref={ref}>
                        <span>{label}</span>
                        {required && <span className='form-field-required'>*</span>}
                    </div>
                    <div className={classes}>{children}</div>
                    {error && <div className={classes}>{error}</div>}
                </>
            );
        }
    }
);
