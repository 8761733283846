import { Button, Card } from 'antd';
import React from 'react';
import { FormSection } from '../../components';
import './landing-view.scss';
import { ArrowRightOutlined } from '@ant-design/icons';

type Props = {
    setCustomerLogin: (value: boolean) => void;
    proceed: () => void;
};

export const LandingView: React.FC<Props> = ({ setCustomerLogin, proceed }: Props) => {
    const customerLogin = (): void => {
        setCustomerLogin(true);
        proceed();
    };

    const dealerLogin = (): void => {
        setCustomerLogin(false);
        proceed();
    };

    return (
        <Card className='view-card width-limited-card login-view'>
            <h3>{'Willkommen im JLR Insurance Services Prämien-Rechner.'}</h3>
            <FormSection>
                <div>
                    <Button
                        className='navigation-button inverse-colored-button landing-view-button'
                        type='primary'
                        onClick={customerLogin}
                    >
                        {'Ich bin Kunde'}
                        <div style={{ flexGrow: 1 }}></div>
                        <ArrowRightOutlined className='arrow-right' />
                    </Button>
                </div>
                <div>
                    <Button
                        className='navigation-button  landing-view-button'
                        type='primary'
                        onClick={dealerLogin}
                    >
                        {'Ich bin Händler'}
                        <div style={{ flexGrow: 1 }}></div>
                        <ArrowRightOutlined className='arrow-right' />
                    </Button>
                </div>
            </FormSection>
        </Card>
    );
};
