import { useState } from 'react';

export const useDebounce = <T = unknown, R = void>(
    callback: (args: T) => R,
    wait: number
): [(args: T) => Promise<R>, () => void] => {
    const [timer, setTimer] = useState<NodeJS.Timeout>();

    const debounce = (args: T): Promise<R> => {
        return new Promise((resolve) => {
            if (timer) {
                clearTimeout(timer);
            }
            setTimer(setTimeout(() => resolve(callback(args)), wait));
        });
    };

    const abort = (): void => clearTimeout(timer);

    return [debounce, abort];
};
