import React, { ReactNode } from 'react';
import './form-section.scss';

type Props = {
    children: ReactNode;
};

export const FormSection: React.FC<Props> = ({ children }: Props) => {
    return <div className='form-section'>{children}</div>;
};
